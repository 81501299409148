import React from "react"
import styles from "./Card.module.css"
import Image from "gatsby-image"
import {Typography} from "@sivasifr/ui-core/Typography"
import {Chip} from "@sivasifr/ui-core/Chip"
import clsx from 'clsx'

const CardDefault = (props) => {
  const {poster, date, title, logLine, tags} = props
  return (
    <>
      <header>
        <Image
          fluid={{
            width: 330,
            height: 200,
            src: poster,
            srcSet: poster,
            aspectRatio: 1.65
          }}
          objectFit="cover"
          alt={``}
        />
        <Typography
          component="h2"
          font="accent"
          className={clsx(
            "pl-3 mt-3",
            styles.headline
          )}
        >
          <span
            itemProp="headline"
          >
            {title}
          </span>
        </Typography>
        <small>{date?.seconds}</small>
      </header>
      <section className="px-3 pb-3 pt-2">
        <p
          dangerouslySetInnerHTML={{
            __html: logLine,
          }}
          itemProp="description"
        />
      </section>
      {
        tags.length > 0 && (
          <section className="px-3 pb-3">
            {
              tags.map(({id, label}) => (
                <span key={id} className={styles.sublink}>
                  <Chip
                    className="mr-1"
                    label={label}
                    color="primary"
                    component="a"
                    href={`/tags/${id}`}
                    clickable
                  />
                </span>
              ))
            }
          </section>
        )
      }
    </>
  )
}

CardDefault.displayName = 'CardDefault'

export default CardDefault