import React from "react"
import styles from "./Card.module.css"
import clsx from 'clsx'

const CardPoster = (props) => {
  const {children, alignCenter} = props
  return (
    <div className={clsx(styles.veil, {[styles.alignCenter]: alignCenter})}>
      <div className={styles.inner}>
        {children}
      </div>
    </div>
  )
}

CardPoster.displayName = 'CardPoster'

export default CardPoster