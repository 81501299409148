import React from "react"
import {Typography} from "@sivasifr/ui-core/Typography"
import Image from "gatsby-image"
import Link from '@sivasifr/ui-core/Link'

const CardFlyer = (props) => {
  const {title, poster, logLine} = props

  return (
    <div className="p-3">
      <div className="row">
        <div className="col-4 d-flex flex-column">
          <Image
            fluid={{
              width: 330,
              height: 200,
              src: poster,
              srcSet: poster,
              aspectRatio: 1.65
            }}
            objectFit="cover"
            alt={``}
          />
        </div>
        <div className="col-8">
          <section>
            <Typography
              component="h2"
              itemProp="headline"
              className="pt-2"
              font="accent"
            >
              {title}
            </Typography>
            <p
              dangerouslySetInnerHTML={{
                __html: logLine,
              }}
              itemProp="description"
            />
            <Link className="mt-auto mr-auto">Читать далее</Link>
          </section>
        </div>
      </div>
    </div>
  )
}

CardFlyer.displayName = 'CardFlyer'

export default CardFlyer