import React from "react"
import CardBase from './CardBase'
import CardDefault from './CardDefault'
import CardPoster from './CardPoster'
import CardFlyer from './CardFlyer'

const Card =  React.forwardRef(function(props, ref) {
  const {
    title,
    poster,
    date,
    logLine,
    slug,
    tags,
    type = 'DEFAULT',
    children,
    aspectRatio,
    className,
    bg,
    picture,
    alignCenter,
  } = props

  return (
    <CardBase
      slug={slug}
      ref={ref}
      aspectRatio={aspectRatio}
      className={className}
      bg={bg}
      picture={picture}
    >
      {
        type === 'DEFAULT' && (
          <CardDefault
            title={title}
            poster={poster}
            date={date}
            logLine={logLine}
            tags={tags}
          />
        )
      }
      {
        type === 'POSTER' && (
          <CardPoster children={children} alignCenter={alignCenter}/>
        )
      }
      {
        type === 'FLYER' && (
          <CardFlyer
            title={title}
            poster={poster}
            logLine={logLine}
          />
        )
      }
    </CardBase>
  )
})

Card.displayName = 'Card'

export default Card