import React from "react"
import styles from "./Card.module.css"
import {Link} from "gatsby"
import clsx from 'clsx'
import {Buttress} from "@sivasifr/ui-core/Buttress"


const CardBase =  React.forwardRef(function(props, ref) {
  const {
    children,
    slug,
    aspectRatio,
    className,
    bg,
    picture,
    pictureAlt,
  } = props
  const pictureProp = Array.isArray(picture) ? picture : picture ? [{src: picture}] : []
  return (
    <Buttress
      {...(bg && {background: bg})}
      aspectRatio={aspectRatio}
      overflowHidden
      className={clsx(
        className,
        styles.card,
        {[styles.hasLink]: !!slug }
      )}
    >
      {
        slug && (
          <Link
            to={slug}
            itemProp="url"
            className={styles.link}
          />
        )
      }
      {
        pictureProp.length > 0 && (
          <div className={styles.imgContent}>
            <picture>
              {
                pictureProp.map((source) => (
                  <source srcSet={source.src} media={source.media} />
                ))
              }
              <img
                src={pictureProp[0].src}
                alt={pictureAlt}
                loading="lazy"
                style={{
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center center',
                  opacity: 1,
                  transition: 'none 0s ease 0s',
                }}
              />
            </picture>
          </div>
        )
      }
      {children}
    </Buttress>
  )
})

CardBase.displayName = 'CardBase'

export default CardBase